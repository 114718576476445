let SDK;
let instance;

export const STEP = {
  ERROR: 'ERROR',
  WAITING_ROOM: 'WAITING_ROOM',
  CALLING: 'CALLING',
  POST_CALLING: 'POST_CALLING',
  COUNTDOWN: 'COUNTDOWN',
};

const { STATUS, EVENT, REMOTE_STATUS, REMOTE_EVENT, ERROR, createInstance } = window.PlatformSDK;

const getDefaultState = () => ({
  isNotificationSoundEnabled: false,
  step: STEP.WAITING_ROOM,
  errorMessage: null,
  errorCode: null,
  isDestroying: false,
  remoteTerminated: false,
  local: {
    status: STATUS.DISCONNECTED,
    audio: {
      deviceId: null,
      stream: null,
      enabled: false,
      devices: [],
    },
    video: {
      deviceId: null,
      stream: null,
      enabled: false,
      devices: [],
    },
  },
  remote: {
    status: REMOTE_STATUS.DISCONNECTED,
    audio: {
      stream: null,
      enabled: false,
    },
    video: {
      stream: null,
      enabled: false,
    },
  },
});

export default {
  state: getDefaultState(),
  mutations: {
    SDK_SET_STEP(state, { step }) {
      state.step = step;
    },
    SDK_SET_STEP_ERROR(state, { message, sdkError }) {
      state.step = STEP.ERROR;
      state.errorMessage = message;
      state.errorCode = sdkError?.name;
    },
    SDK_SET_LOCAL_STATUS(state, { status }) {
      state.local.status = status;
    },
    SDK_SET_REMOTE_STATUS(state, { status }) {
      state.remote.status = status;
    },
    SDK_SET_REMOTE_STREAM(state, { kind, stream }) {
      state.remote[kind].stream = stream;
    },
    SDK_SET_REMOTE_DEVICE_ENABLED(state, { kind, enabled }) {
      state.remote[kind].enabled = enabled;
    },
    SDK_SET_NOTIFICATION_SOUND_ENABLED(state, { enabled }) {
      state.isNotificationSoundEnabled = enabled;
    },
    SDK_SET_LOCAL_DEVICE_ENABLED(state, { kind, enabled }) {
      state.local[kind].enabled = enabled;
    },
    SDK_SET_LOCAL_DEVICE_ID(state, { kind, deviceId }) {
      state.local[kind].deviceId = deviceId;
    },
    SDK_SET_LOCAL_VIDEO_STREAM(state, { stream }) {
      state.local.video.stream = stream;
    },
    SDK_SET_LOCAL_DEVICES(state, { kind, devices }) {
      state.local[kind].devices = devices;
    },
    SDK_SET_REMOTE_TERMINATED(state, { terminated }) {
      state.remoteTerminated = terminated;
    },
    SDK_SET_DESTROYING(state, { isDestroying }) {
      state.isDestroying = isDestroying;
    },
    SDK_RESET(state) {
      Object.assign(state, getDefaultState());
      instance = undefined;
    },
    SDK_SET_INSTANCE(state, { instance: sdkInstance }) {
      instance = sdkInstance;
    },
  },
  actions: {
    async sdkCreateInstance({ commit, dispatch, state }, { encounterId, apiKey, apiToken }) {
      if (instance) {
        await dispatch('sdkDestroy');
      }

      try {
        commit('SDK_SET_INSTANCE', { instance: await createInstance(encounterId, apiKey, apiToken) });
      } catch (e) {
        commit('SDK_SET_STEP_ERROR', {
          message: 'Une erreur s\'est produite lors de l\'initialisation.',
          sdkError: e,
        });
        throw e;
      }

      /**
       * LOCAL EVENTS
       **/

      // WORKFLOW

      instance.on(EVENT.STATUS_CHANGED, ({ to }) => {
        commit('SDK_SET_LOCAL_STATUS', { status: to });
      });

      instance.on(EVENT.CONNECTED, () => {
        commit('SDK_SET_STEP', { step: STEP.WAITING_ROOM });
      });

      instance.on(EVENT.JOINED, () => {
        commit('SDK_SET_STEP', { step: STEP.CALLING });
      });

      instance.on(EVENT.LEAVED, () => {
        commit('SDK_SET_STEP', { step: STEP.WAITING_ROOM });
      });

      instance.on(EVENT.DISCONNECTED, async ({ unexpected }) => {
        if (unexpected) {
          commit('SDK_SET_STEP_ERROR', {
            message: 'La connexion a été perdue. Tentative de reconnexion...',
          });
          dispatch('sdkConnect');
        } else {
          commit('SDK_SET_STEP', { step: state.remoteTerminated ? STEP.POST_CALLING : STEP.WAITING_ROOM });
        }
      });

      instance.on('#PRACTITIONER_TERMINATED', async () => {
        commit('SDK_SET_REMOTE_TERMINATED', { terminated: true });
        dispatch('sdkDisconnect');
      });

      // DEVICES

      instance.on(EVENT.DEVICES_LIST_CHANGED, ({ audio, video }) => {
        commit('SDK_SET_LOCAL_DEVICES', { kind: 'audio', devices: audio });
        commit('SDK_SET_LOCAL_DEVICES', { kind: 'video', devices: video });
      });

      instance.on(EVENT.DEVICES_STARTED, ({ audio, video }) => {
        commit('SDK_SET_LOCAL_DEVICE_ID', { kind: 'audio', deviceId: audio.deviceId });
        commit('SDK_SET_LOCAL_DEVICE_ENABLED', { kind: 'audio', enabled: audio.enabled });

        commit('SDK_SET_LOCAL_DEVICE_ID', { kind: 'video', deviceId: video.deviceId });
        commit('SDK_SET_LOCAL_DEVICE_ENABLED', { kind: 'video', enabled: video.enabled });
        commit('SDK_SET_LOCAL_VIDEO_STREAM', { stream: video.stream });
      });

      instance.on(EVENT.DEVICE_TOGGLED, ({ kind, enabled }) => {
        commit('SDK_SET_LOCAL_DEVICE_ENABLED', { kind, enabled });
      });

      instance.on(EVENT.DEVICE_CHANGED, ({ kind, deviceId, enabled, stream }) => {
        commit('SDK_SET_LOCAL_DEVICE_ENABLED', { kind, enabled });
        commit('SDK_SET_LOCAL_DEVICE_ID', { kind, deviceId });
        if (kind === 'video') {
          commit('SDK_SET_LOCAL_VIDEO_STREAM', { stream });
        }
      });

      instance.on(EVENT.DEVICES_STOPPED, () => {
        commit('SDK_SET_LOCAL_DEVICE_ID', { kind: 'audio', deviceId: null });
        commit('SDK_SET_LOCAL_DEVICE_ENABLED', { kind: 'audio', enabled: false });

        commit('SDK_SET_LOCAL_DEVICE_ID', { kind: 'video', deviceId: null });
        commit('SDK_SET_LOCAL_DEVICE_ENABLED', { kind: 'video', enabled: false });
        commit('SDK_SET_LOCAL_VIDEO_STREAM', { stream: null });
      });

      // WORKFLOW ERRORS

      instance.on(EVENT.CONNECT_ERROR, ({ error }) => {
        let message = 'Erreur de connexion. Veuillez rafraichir la page et réessayer.';
        switch (error.name) {
          case ERROR.NO_MEDIA_DEVICE_AVAILABLE:
            message = 'Aucun dispositif audio ou video n\'a été détecté';
            break;
          case ERROR.UNABLE_TO_CONNECT:
            message = 'Une erreur est survenue lors de la connexion.';
            error = error.previousError;
        }

        commit('SDK_SET_STEP_ERROR', { message, sdkError: error });
      });

      instance.on(EVENT.JOIN_ERROR, ({ error }) => {
        commit('SDK_SET_STEP_ERROR', {
          message: 'Une erreur s\'est produite. Veuillez rafraichir la page et réessayer.',
          sdkError: error,
        });
      });

      // DEVICES ERRORS

      instance.on(EVENT.CHANGE_DEVICE_ERROR, ({ error }) => {
        dispatch('appNotificationAddError', { message: `Impossible de sélectionner le dispositif (Code erreur: ${error.name})` });
      });

      instance.on(EVENT.START_DEVICES_ERROR, ({ error }) => {
        let message = error.name === ERROR.UNABLE_TO_START_AUDIO_DEVICE
          ? 'Impossible de démarrer votre microphone. Veuillez vérifier qu\'il est connecté et autorisé par le navigateur'
          : 'Impossible de démarrer votre caméra. Veuillez vérifier qu\'elle est connectée et autorisée par le navigateur';

        commit('SDK_SET_STEP_ERROR', {
          message,
          sdkError: error,
        });
      });

      /**
       * REMOTE EVENTS
       */

      // WORKFLOW

      instance.on(REMOTE_EVENT.STATUS_CHANGED, ({ to }) => {
        commit('SDK_SET_REMOTE_STATUS', { status: to });
      });

      instance.on(REMOTE_EVENT.CONNECTED, async () => {
        commit('SDK_SET_STEP', { step: STEP.COUNTDOWN });

        if (state.isNotificationSoundEnabled) {
          (new Audio(require('../../assets/notification.mp3'))).play();
        }
      });

      instance.on(REMOTE_EVENT.JOINED, ({ audio, video }) => {
        commit('SDK_SET_REMOTE_STREAM', { kind: 'audio', stream: audio.stream });
        commit('SDK_SET_REMOTE_STREAM', { kind: 'video', stream: video.stream });
        commit('SDK_SET_REMOTE_DEVICE_ENABLED', { kind: 'audio', enabled: audio.enabled });
        commit('SDK_SET_REMOTE_DEVICE_ENABLED', { kind: 'video', enabled: video.stream });

        if (state.local.status === STATUS.CONNECTED) {
          commit('SDK_SET_STEP', { step: STEP.COUNTDOWN });
        }
      });

      instance.on(REMOTE_EVENT.LEAVED, async () => {
        commit('SDK_SET_REMOTE_STREAM', { kind: 'audio', stream: null });
        commit('SDK_SET_REMOTE_STREAM', { kind: 'video', stream: null });
      });

      instance.on(REMOTE_EVENT.DISCONNECTED, async() => {
        if (state.local.status === STATUS.JOINED) {
          await dispatch('sdkLeave');
        }
      })

      // DEVICES

      instance.on(REMOTE_EVENT.DEVICE_CHANGED, ({ kind, enabled, stream }) => {
        commit('SDK_SET_REMOTE_DEVICE_ENABLED', { kind, enabled });
        commit('SDK_SET_REMOTE_STREAM', { kind, stream });
      });

      instance.on(REMOTE_EVENT.DEVICE_TOGGLED, ({ kind, enabled }) => {
        commit('SDK_SET_REMOTE_DEVICE_ENABLED', { kind, enabled });
      });

      try {
        await instance.startMediaDevices();
      } catch(e) {
        commit('SDK_SET_STEP_ERROR', {
          message: 'Démarrage de vos dispositifs audio et/ou vidéo impossible.',
          sdkError: e,
        });
      }

      commit('SDK_SET_LOCAL_DEVICES', { kind: 'audio', devices: instance.audioDevices });
      commit('SDK_SET_LOCAL_DEVICES', { kind: 'video', devices: instance.videoDevices });
    },
    sdkEnableNotificationSound({ commit }) {
      commit('SDK_SET_NOTIFICATION_SOUND_ENABLED', { enabled: true });
    },
    async sdkConnect() {
      await instance.connect();
    },
    async sdkDisconnect() {
      await instance.disconnect();
    },
    async sdkJoin() {
      await instance.join();
    },
    async sdkLeave() {
      await instance.leave();
    },
    sdkToggleVideoDevice() {
      instance.toggleVideoDevice();
    },
    sdkToggleAudioDevice() {
      instance.toggleAudioDevice();
    },
    sdkChangeAudioDevice(_, { deviceId }) {
      instance.changeAudioDevice(deviceId).catch(error => {
        throw error;
      });
    },
    sdkChangeVideoDevice(_, { deviceId }) {
      instance.changeVideoDevice(deviceId).catch(error => {
        throw error;
      });
    },
    async sdkDestroy({ commit, state, dispatch }) {
      if (!instance) {
        return;
      }

      commit('SDK_SET_DESTROYING', { destroying: true });

      try {
        await dispatch('sdkDisconnect');
      } finally {
        instance?.stopMediaDevices(true);
        commit('SDK_RESET');
      }
    },
    sdkAddEvent(_, { name, callback }) {
      instance.on(name, callback);
    },
    sdkRemoveEvent(_, { name, callback }) {
      instance.off(name, callback);
    },
    sdkEmitRemoteEvent(_, { name }) {
      instance.emitRemote(name);
    },
  },
  // WARNING : Don't access sdk instance from getters (it mutates the instance). Use actions instead.
  getters: {
    sdkCanJoin(state) {
      return state.local.status === STATUS.CONNECTED
        && state.remote.status === REMOTE_STATUS.JOINED;
    },
    sdkShowDeviceSelector(state) {
      return state.local.audio.devices.length > 1 || state.local.audio.devices.length > 1;
    },
  },
};
